import React, { useContext } from 'react';

import { FormContext } from '../context';
import Label from '../label';

export default function Select({ field }) {
  const { handleChange } = useContext(FormContext);
  return (
    <label htmlFor={field.id}>
      <Label label={field.label} required={field.required} />
      <select onChange={handleChange} onBlur={handleChange} {...field}>
        {field.options.map(option => (
          <option
            key={option.option}
            value={option.disabled ? '' : option.option}
            disabled={option.disabled}
          >
            {option.option}
          </option>
        ))}
      </select>
    </label>
  );
}
